import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LoadingPopupComponent } from 'src/app/components/ui/loading-popup/loading-popup.component';
import { DeviceService } from 'src/app/services/devices/device.service';
import { ModalService } from 'src/app/services/modal/modal.service';

@Component({
  selector: 'app-ocpp',
  templateUrl: './ocpp.component.html',
  styleUrls: ['./ocpp.component.scss']
})
export class OcppComponent implements OnInit {

  @Input() allSettings=false;

  modalLoad: NgbModalRef;
  device: string = '';
  ocppCfg={serverUrl:'', port:80};

  originalData: any;
  changesMade=false;
  successMessage: boolean = false;
  error: string = '';
  bgColorTest = '';
  iconColorTest = '#00000'
  bgColorReset = '';
  iconColorReset = '#00000'
  bgColorCfg = '';
  iconColorCfg= '#00000'
  spinnerTest = false;
  spinnerReset = false;
  spinnerCfg = false;
  warning = false;


  constructor(public _device: DeviceService, private _modal: ModalService, private _aRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this._aRoute.parent?.parent?.params
    .subscribe((params: Params) => this.device = params['serial']);

    this._device.getModuleInfo(this.device, 'ocpp')
      .subscribe(data=>{
        console.log(data);
        this.ocppCfg=data.cfg;
        this.originalData={...data.cfg};
      })
  }

  save(){
    this.modalLoad=this._modal.open(LoadingPopupComponent);

    this._device.setModuleCfg(this.device, this.ocppCfg, 'ocpp')
      .subscribe(data=>{
        console.log(data);
        this.modalLoad.close();
        this.successMessage=true;
        setTimeout(() => {
          this.successMessage=false;
        }, 10000);
      }, err=>{
        console.error(err)
        this.error=err.error.message;
        setTimeout(() => {
          this.error='';
        }, 10000);
        this.modalLoad.close();
      })
  }

  test(){
    this.spinnerTest = true;
    this.warning = false;
    this.bgColorCfg = '';

      this._device.getModuleInfo(this.device,  'ocpp', 'stat', {localdata: true})
        .subscribe( data => {
          setTimeout(() => {
            data.stat.wsConnected == 0 ? this.bgColorTest ='#EB3C3C' : this.bgColorTest = '#39b690'
              this.spinnerTest = !this.spinnerTest
              this.iconColorTest = 'white'
              this.warning = false;
            }, 1000);

        }, err => {
          setTimeout(() => {
            this.bgColorTest = '#f8f32b';
            this.iconColorTest = '';
            this.spinnerTest = !this.spinnerTest;
            this.warning = true
            console.log(err);
          }, 1000);
        })
  }

  deleteCfg(){
    this.bgColorTest = '';
    this.iconColorTest = 'black'
    this.spinnerCfg = true
    this.warning = false;



    this._device.setModuleCfg(this.device,  {serverUrl:'', port:80}, 'ocpp')
    .subscribe ( (data) => {
      setTimeout(() => {

        console.log(data);
        this.ocppCfg.serverUrl = data.cfg.serverUrl;
        this.ocppCfg.port = data.cfg.port
        this.spinnerCfg = false;
        this.bgColorCfg = '#39b690'
        this.iconColorCfg = 'white'
        setTimeout(() => {
          this.bgColorCfg = ''
          this.iconColorCfg = ''
        }, 2000);
      }, 1000);

    }, err => {
      setTimeout(() => {
        this.bgColorCfg = '#f8f32b';
        this.iconColorCfg = '';
        this.spinnerCfg = !this.spinnerCfg;
        this.warning = true
        console.log(err);
      }, 1000);
    })

  }

  reset(){
    this.spinnerReset = true

    console.log('reset')
    this.bgColorTest =''
    this.iconColorTest='#000000'
    this.bgColorCfg = '';
    this._device.reset(this.device, 'hard')
      .subscribe( ()=>{
        setTimeout(() => {
          this.spinnerReset =!this.spinnerReset
          this.bgColorReset = '#39b690';
          console.log('reset ok');
          this.warning = false;
          this.resetButton();
        }, 1000);
      }, err => {
        setTimeout(() => {
          this.spinnerReset =!this.spinnerReset;
          this.bgColorReset = '#EB3C3C'
          console.log(err)
          this.resetButton();
        }, 1000);
      })

  }

  resetButton(){
    setTimeout(() => {
      this.iconColorTest = '#00000'
      this.bgColorReset = '';
    }, 3000);

  }  changePort(){
    if  (this.ocppCfg.port <1 ) this.ocppCfg.port = 1;
    if  (this.ocppCfg.port >65535 ) this.ocppCfg.port = 65535;
  }

  changeUrl(){

    const regex = /^(ws:\/\/|wss:\/\/)(([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}|(\d{1,3}\.){3}\d{1,3})(:(6553[0-5]|655[0-2]\d|65[0-4]\d{2}|6[0-4]\d{3}|[1-5]\d{4}|\d{1,4}))?\/[^\/@!#$%^&*()+=._-][^@!#$%^&*()+=]*$/;
    if (regex.test(this.ocppCfg.serverUrl.toLocaleLowerCase())) {
      return true
      } else {
     return false
    }
  }


}
