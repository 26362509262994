<div [ngStyle]="{'height': sidebarHeight, 'top': sidebarTop}" class="prueba-sidebar">
  <nav id="sidebar" class="sidebar h-100">
    <!-- <a class="sidebar-brand"  href="#/dashboard"  >
      <div class="img-logo d-flex align-items-center">
        <img src="/assets/img/orbis/orbisIcon.png" width="50" height="50" alt="">
        <h1 class="m-0 text-white text-logo-icon ml-3">RBIS</h1>
      </div>
    </a> -->
    <div class="sidebar-content">
      <div class="sidebar-user">
        <div class="font-weight-bold" *ngIf="name != ''  || last_name != '' ">{{userName}}</div>
        <div class="font-weight-bold" *ngIf="last_name == '' && name =='' " >{{userEmail}}</div>
        <small>{{userRole}}</small>
      </div>

      <ul class="sidebar-nav">
        <li class="sidebar-header" *ngIf="userRole=='admin' || userRole=='developer'">
          {{"areas" | translate}}
        </li>

        <div *ngIf="userRole =='admin'">
          <div class="accordion" id="accordionAdmin">
            <!--ADMINISTRATION-->
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button collapsed " type="button" data-bs-toggle="collapse" data-bs-target="#collapseAdmin"
                  aria-expanded="true" aria-controls="collcollapseAdminapseOne">
                  <i class="fas fa-file-signature mr-2 "></i>
                  <span class="align-middle ">{{"administration" | translate}}</span>
                </button>
              </h2>

              <div id="collapseAdmin" class="accordion-collapse collapse" data-bs-parent="#accordionAdmin">
                <div class="accordion-body">
                  <li class="sidebar-item">
                    <a class="sidebar-link" (click)="deleteUserDevice()"
                      routerLink="/dashboard/administration/search">{{"search" | translate}}</a>
                  </li>
                  <li class="sidebar-item">
                    <a class="sidebar-link" (click)="deleteUserDevice()"
                      routerLink="/dashboard/administration/addUser">{{"addUserToDevice" | translate}}</a>
                  </li>
                  <li class="sidebar-item">
                    <a class="sidebar-link" routerLink="/dashboard/administration/sendCommand">{{"sendCommands" |
                      translate}}</a>
                  </li>
                  <li class="sidebar-item">
                    <a class="sidebar-link" routerLink="/dashboard/administration/testers">{{"testers" | translate}}</a>
                  </li>
                </div>
              </div>
            </div>
            <!--DIAGNOSIS-->
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseDiag"
                  aria-expanded="true" aria-controls="collcollapseAdminapseOne">
                  <i class="fas fa-stethoscope mr-2"></i>
                  <span class="align-middle">{{"diagnosis" | translate}}</span>
                </button>
              </h2>
              <div id="collapseDiag" class="accordion-collapse collapse" data-bs-parent="#accordionAdmin">
                <div class="accordion-body">

                  <li class="sidebar-item"><a class="sidebar-link"
                      (click)="clickDiagnosis();deleteUserDevice()">{{"HomeSidebar" | translate}}</a></li>
                  <li class="sidebar-item">
                    <a class="sidebar-link" (click)="deleteUserDevice()"
                      routerLink="/dashboard/administration/logs">{{"historicSidebar" | translate}}</a>
                  </li>
                </div>
              </div>
            </div>
            <!--DOCUMENTATION-->
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseDocu"aria-expanded="false" aria-controls="collcollapseAdminapseOne">
                  <i class="fas fa-book mr-2"></i> <span class="align-middle">{{"documentation" | translate}}</span>
                </button>
              </h2>
              <div id="collapseDocu" class="accordion-collapse collapse" data-bs-parent="#accordionAdmin">
                <div class="accordion-body">
                  <li class="sidebar-item"><a class="sidebar-link" routerLink="/developers/documentation/mqtt_api">{{"mqttApi" | translate}}</a></li>
                </div>
              </div>
            </div>
            <!--DEVELOPER SETTINGS-->
            <div class="accordion-item" *ngIf="userRole=='admin' || userRole=='developer'">
              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseDevSettings"aria-expanded="false" aria-controls="collcollapseAdminapseOne">
                  <i class="fas fa-code mr-2"></i> <span class="align-middle">{{"developerSettings" | translate}}</span>
                </button>
              </h2>
              <div id="collapseDevSettings" class="accordion-collapse collapse" data-bs-parent="#accordionAdmin">
                <div class="accordion-body">
                  <li class="sidebar-item"><a class="sidebar-link" routerLink="/developers/settings/pat-token">{{"patToken" | translate}}</a></li>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--DEVICES-->

        <div class="p-0" *ngIf="userRole =='admin'">
          <!-- Sidebar -->
          <div class="" id="sidebar-wrapper">


            <div class="d-flex w-100 m-auto button-group-container ">
              <div class=" w-50 text-center p-1 button-group" (click)="changeView()"
              [ngClass]="{'bg-primary text-white' : view === 'devices' }" data-cy="sidebarDevicesButton">
              {{"devices" | translate}}
              </div>
              <div class="w-50 text-center p-1 button-group" (click)="changeView()"
              [ngClass]="{'bg-primary text-white' : view === 'groups' }" data-cy="sidebarGroupsButton" >
                {{"groups" | translate}}
              </div>
            </div>


            <div class="p-1 ms-2 mt-3 text-muted" *ngIf="view === 'groups'">
              <div *ngFor="let node of data" class="mb-2" style="cursor: pointer;">
                <!-- Nodo Padre -->
                <div (click)="toggleNode(node, node.devices)" class="d-flex align-items-center">
                  <span><i class="fas fa-layer-group me-1"></i></span>
                  <span [ngClass]="{
                    'selected-item': node.alias?.toLowerCase() === paramInfoGroup?.toLowerCase() ||
                                    node.alias?.toLowerCase() === paramGroup?.toLowerCase() ||
                                    node.alias?.toLowerCase() === paramViewGroup?.toLowerCase() ||
                                    node.alias?.toLowerCase() === paramSplGroup?.toLowerCase() ||
                                    node.alias?.toLowerCase() === selectedNode?.toLowerCase()
                  }">{{ node.name }}</span>
                  <span *ngIf="node.isExpanded"><i class="fas fa-chevron-down"></i></span>
                  <span *ngIf="!node.isExpanded"><i class="fas fa-chevron-right"></i></span>
                </div>

                <!-- Subgrupos del nodo padre al expandir -->
                <div *ngIf="node.isExpanded">
                  <div *ngFor="let child of node.subgroups" class="ms-4 mt-2">
                    <!-- Subgrupo -->
                    <div (click)="clickGroup(node, child)" class="d-flex align-items-center">
                      <i class="fas fa-layer-group"></i>
                      <span [ngClass]="{
                        'selected-item': child.alias?.toLowerCase() === paramInfoGroup?.toLowerCase() ||
                                        child.alias?.toLowerCase() === paramGroup?.toLowerCase() ||
                                        child.alias?.toLowerCase() === paramViewGroup?.toLowerCase() ||
                                        child.alias?.toLowerCase() === paramSplGroup?.toLowerCase() ||
                                        child.alias?.toLowerCase() === selectedNode?.toLowerCase()
                      }">{{ child.name }}</span>
                      <span *ngIf="child.isExpanded"><i class="fas fa-chevron-down"></i></span>
                      <span *ngIf="!child.isExpanded"><i class="fas fa-chevron-right"></i></span>
                    </div>

                    <!-- Subgrupos y dispositivos del hijo -->
                    <div *ngIf="child.isExpanded">
                      <!-- Subgrupos del hijo -->
                      <div *ngFor="let grandchild of child.children" class="ms-4 mt-2">
                        <div (click)="clickGrandchild(grandchild)" class="d-flex align-items-center">
                          <i class="fas fa-layer-group"></i>
                          <span [ngClass]="{
                            'selected-item': grandchild.alias?.toLowerCase() === paramInfoGroup?.toLowerCase() ||
                                            grandchild.alias?.toLowerCase() === paramGroup?.toLowerCase() ||
                                            grandchild.alias?.toLowerCase() === paramViewGroup?.toLowerCase() ||
                                            grandchild.alias?.toLowerCase() === paramSplGroup?.toLowerCase() ||
                                            grandchild.alias?.toLowerCase() === selectedNode?.toLowerCase()
                          }">{{ grandchild.name }}</span>
                          <span *ngIf="grandchild.isExpanded"><i class="fas fa-chevron-down"></i></span>
                          <span *ngIf="!grandchild.isExpanded"><i class="fas fa-chevron-right"></i></span>
                        </div>

                        <!-- Dispositivos del nieto -->
                        <div *ngIf="grandchild.isExpanded" class="ms-4">
                          <div *ngFor="let device of grandchild.devices" (click)="clickDevice(device.serial)" class="d-flex align-items-center mt-1" style="cursor: pointer;">
                            <i class="fas fa-charging-station me-1"></i>
                            <span [ngClass]="{'selected-item': device.serial?.toLowerCase() === paramDevice?.toLowerCase()}">{{ device.serial }}</span>
                          </div>
                        </div>
                      </div>

                      <!-- Línea de separación -->
                      <hr class="m-1" *ngIf="child.children?.length > 0 && child.devices?.length > 0">

                      <!-- Dispositivos del hijo -->
                      <div *ngFor="let device of child.devices" (click)="clickDevice(device.serial)" class="d-flex align-items-center mt-1 ms-4" style="cursor: pointer;">
                        <i class="fas fa-charging-station me-1"></i>
                        <span [ngClass]="{'selected-item': device.serial?.toLowerCase() === paramDevice?.toLowerCase()}">{{ device.serial }}</span>
                      </div>
                    </div>
                  </div>

                  <!-- Dispositivos del nodo padre -->
                  <hr class="m-1" *ngIf="node.subgroups?.length > 0 && node.devices?.length > 0">
                  <div *ngFor="let device of node.devices" (click)="clickDevice(device.serial)" class="d-flex align-items-center mt-1 ms-3" style="cursor: pointer;">
                    <i class="fas fa-charging-station me-1"></i>
                    <span [ngClass]="{'selected-item': device.serial?.toLowerCase() === paramDevice?.toLowerCase()}">{{ device.serial }}</span>
                  </div>
                </div>
              </div>
            </div>




            <div *ngIf="view=='devices'" >

              <li class="sidebar-header">
                {{"devices" | translate}}
                <form class="form-inline d-none d-sm-inline-block mt-2">
                  <input class="form-control" type="text" name="filteredSerial" [(ngModel)]="filteredSerial"
                  placeholder="{{'searchDevices' | translate}}" data-cy="sidebarSearchDevices" >
                </form>
              </li>
              <div class="devices-sidebar"  [ngStyle]="{'max-height': devicesHeight}"  >
                <li class="sidebar-item" *ngFor="let device of devices | filterDevices:filteredSerial; let i = index">
                  <a class="sidebar-link collapsed" (click)="clickDevice(device.serial)">
                    <i class="fas fa-charging-station me-1 mt-1 text-success" *ngIf="device.alive == 1" ></i>
                    <i class="fas fa-charging-station me-1 mt-1 text-danger" *ngIf="device.alive == 0" ></i>
                    <span [attr.data-cy]="'sidebarDevice['+i+']'"
                    class="align-middle">{{device.serial}}</span>&nbsp;
                    <!-- <i *ngIf="device.alive == 1" class="	fas fa-circle text-success"></i>
                    <i *ngIf="device.alive == 0" class="	fas fa-circle text-danger"></i> -->
                  </a>
                </li>
              </div>
            </div>
          </div>
          <button *ngIf="view=='groups'" class="my-float" [style.top]="topPosition"  (click)="addGroup()"  ><i class="fa fa-plus "></i></button>
        </div>


        <!--DEVICES-->
        <div *ngIf="userRole !='admin'">

          <li class="sidebar-header">
            {{"devices" | translate}}
            <form class="form-inline d-none d-sm-inline-block mt-2">
              <input class="form-control" type="text" name="filteredSerial" [(ngModel)]="filteredSerial"
              placeholder="{{'searchDevices' | translate}}">
            </form>
          </li>
          <div class="devices-sidebar">
            <li class="sidebar-item" *ngFor="let device of devices | filterDevices:filteredSerial">
              <a class="sidebar-link collapsed" (click)="clickDevice(device.serial)">
                <i class="align-middle mr-2 fas fa-fw fa-tablet text-black"></i> <span
                class="align-middle">{{device.serial}}</span>&nbsp;
                <i *ngIf="device.alive == 1" class="	fas fa-circle text-success"></i>
                <i *ngIf="device.alive == 0" class="	fas fa-circle text-danger"></i>
              </a>
            </li>
          </div>
        </div>


      </ul>
    </div>
  </nav>
</div>
