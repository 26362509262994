<main class="main h-100 w-100">
  <div class="container h-100">
    <div class="row h-100">
      <div class="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
        <div class="d-table-cell align-middle">

          <!-- Si el modo es resetPassword, mostramos el formulario de recuperar contraseña -->
          <div class="text-center mt-4" *ngIf="mode === 'resetPassword'">
            <h1 class="h2">{{ "welcome" | translate }}</h1>
            <p class="lead">
              {{ "notFilled" | translate }}
            </p>
          </div>

          <div class="card" *ngIf="mode === 'resetPassword'">
            <div class="card-body">
              <div class="m-sm-4">
                <div class="text-center">
                  <img src="/assets/img/orbis/orbis-logo.jpg" alt="Orbis Logo" class="img-fluid" width="132" height="132" />
                </div>
                <form>
                  <div class="form-group">
                    <label>{{ "password_new" | translate }}</label>
                    <input 
                      class="form-control form-control-lg" 
                      [(ngModel)]="userInfo.password" 
                      type="password" 
                      name="password" />
                  </div>
                  <div class="form-group">
                    <label>{{ "password_repeat" | translate }}</label>
                    <input 
                      class="form-control form-control-lg" 
                      [(ngModel)]="rePass" 
                      type="password" 
                      name="rePass" />
                    <small>
                      <a routerLink="/login">{{ "alreadyRegisteredQuestion" | translate }}</a>
                    </small>
                  </div>
                  <div>
                    <label *ngIf="errorPasswords" class="text-small text-danger">
                      {{ "passNotMatch" | translate }}
                    </label>
                  </div>
                  <div class="text-center mt-3">
                    <a class="btn btn-lg btn-primary" (click)="recover()">
                      {{ "reestablish" | translate }}
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <!-- Modo verifyEmail -->
          <div class="card" *ngIf="mode === 'verifyEmail'">
            <div class="card-body">
              <div class="m-sm-4">
                
                <!-- Si el código NO es válido, muestra error -->
                <div *ngIf="!isCodeValid && !isLoading">
                  <h5 class="text-danger">{{ errorMessage }}</h5>
                  <p>{{ "linkNotValid" | translate }}</p>
                  <div class="text-center mt-3">
                    <a class="btn btn-primary" (click)="goToLogin()">
                      {{ "goLogin" | translate }}
                    </a>                    
                  </div>
                </div>
                
                <!-- Si el código ES válido, muestra formulario de nombre/apellidos -->
                <form *ngIf="isCodeValid">
                  <p class="mb-3">
                    {{ "pleaseFillNameSurnameToActivate" | translate }}
                  </p>
                  
                  <div class="form-group">
                    <label>{{ "name" | translate }}</label>
                    <input
                      class="form-control form-control-lg"
                      [(ngModel)]="verifyInfo.name"
                      type="text" 
                      name="name" />
                  </div>

                  <div class="form-group">
                    <label>{{ "surname" | translate }}</label>
                    <input
                      class="form-control form-control-lg"
                      [(ngModel)]="verifyInfo.lastName"
                      type="text" 
                      name="lastName" />
                  </div>
                  
                  <div class="text-center mt-3">
                    <a 
                      class="btn btn-lg btn-primary" 
                      (click)="onVerifyAccount()"
                    >
                      {{ "verify" | translate }}
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</main>
