import { Component, OnInit, ViewChild } from '@angular/core';
import { DeviceService } from 'src/app/services/devices/device.service';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'src/app/services/modal/modal.service';
import { ModalCheckComponent } from 'src/app/components/ui/modal-check/modal-check.component';
import { compare } from 'compare-versions';
import { TestSpl  } from './test/spl';
import { TestSolar } from './test/solar';
import { TestScheduler } from './test/scheduler';


@Component({
  selector: 'app-multiple-command',
  templateUrl: './multiple-command.component.html',
  styleUrls: ['./multiple-command.component.scss']
})
export class MultipleCommandComponent implements OnInit {
  displayedColumns: string[] = ['selectedDev', 'serial', 'type', 'version'];
  displayedColumnsToSend: string[] = [ 'serial', 'version','check' ,'remove'];

  command: number;
  filter: number;
  typeFilter: string;
  versionFilter: string;
  filtersAdded: {
    type: string,
    version: string
  };
  userEmail: string;
  userRole: string;
  dataSource: MatTableDataSource<any>;
  dataSourceToSend: MatTableDataSource<any>;
  serialFilter: string;

  urlUpdate: string = "";
  roleUpdate: string = "admin";

  resetType: string = "hard";

  getAllDevices: boolean = false;
  charging: boolean = false;
  chargingToSend: boolean = false;

  updateStatus: number = 0;

  buttonEnabled : boolean = false;

   taskCheckOption = [
  { module : 'All', check : true, ok : false },
  { module : 'SPL Manager', check : true, ok : false  },
  { module : 'Scheduler Manager', check : true, ok : false  },
  { module : 'Solar Manager', check : true, ok : false  },
  ];

  testList: { serial: string; module: string; test: string; config: Object; result: boolean}[]=[];
  tstList2 : any[]=[];
  modalCheck : NgbModalRef
  version : boolean;

  @ViewChild('paginatorAll') paginatorAll: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('paginatorToSend') paginatorToSend: MatPaginator;

  constructor(private _devices: DeviceService,  private _modal: ModalService) {
    this.command = 0;
    this.filter = 0;
    this.typeFilter = "";
    this.versionFilter = "";
    this.filtersAdded = {type: "", version: ""}
    this.dataSource = new MatTableDataSource();
    this.dataSourceToSend = new MatTableDataSource();
  }

  ngOnInit() {
    this.userEmail = sessionStorage.getItem('email') || '';
    this.userRole = sessionStorage.getItem('role') || '';

    this.getDevices();
  }

  addDevice(device: any) {
    if(!device.selected){
      device.selected = true;
      device.status = 0;
      if(!this.dataSourceToSend.data.find(dev => dev.serial == device.serial)){
        var devices = this.dataSourceToSend.data;
        devices.push(device);
        this.dataSourceToSend.data = devices;
        this.dataSourceToSend.paginator = this.paginatorToSend;
      }
    }
    else{
      device.selected = false;
      var devices = this.dataSourceToSend.data;
      devices = devices.filter(dev => dev.serial != device.serial);
      this.dataSourceToSend.data = devices;
      this.dataSourceToSend.paginator = this.paginatorToSend;
    }
  }

  addFilter(){
    this.chargingToSend=true;
    if(this.filter == 2){
      this.filtersAdded.type = this.typeFilter;
    }
    else if(this.filter == 3){
      this.filtersAdded.version = this.versionFilter;
    }
    setTimeout(() => {
      this.dataSourceToSend.data = this.dataSource.data.filter(device => {
        if((this.filtersAdded.version=="" || device.version == this.filtersAdded.version) && (this.filtersAdded.type=="" || device.type == this.filtersAdded.type)){
          device.selected=true;
          device.status = 0;
          return true
        }
        else{
          device.selected=false;
          return false
        }
      });
      this.dataSourceToSend.paginator = this.paginatorToSend;
      this.chargingToSend=false;
    }, 200);
  }

  changeFilter(){
    if(this.filter==1){
      this.charging=true;
      setTimeout(() => {
        this.dataSource.paginator = this.paginatorAll;
        this.charging=false;
        setTimeout(() => {
          this.dataSource.sort = this.sort;
        }, 200);
      }, 1000);
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  removeDevice(device: any){
    var devices = this.dataSourceToSend.data;
    devices = devices.filter(dev => dev.serial != device.serial);
    this.dataSourceToSend.data = devices;
    this.dataSourceToSend.paginator = this.paginatorToSend;
    this.dataSource.data.find(dev => dev.serial == device.serial).selected = false;
  }

  removeFilter(filter: string){
    if(filter == "type"){
      this.filtersAdded.type = "";
    }
    else if(filter == "version"){
      this.filtersAdded.version = "";
    }
    this.dataSourceToSend.data = this.dataSource.data.filter(device => {
      if(this.filtersAdded.version == "" && this.filtersAdded.type == ""){
        device.selected=false;
        return false;
      }
      if((this.filtersAdded.version=="" || device.version == this.filtersAdded.version) && (this.filtersAdded.type=="" || device.type == this.filtersAdded.type)){
        device.selected=true;
        return true
      }
      else{
        device.selected=false;
        return false
      }
    });
    this.dataSourceToSend.paginator = this.paginatorToSend;
  }

  getLastVersion(){
    if(this.dataSourceToSend.data.length == 0)
      return;

    if(this.dataSourceToSend.data[0].type=="COMBI/CITY"){
      var type = "device_evse";
      var sub_type = "";
    }
    else{
      var type = "device_mod";
      if(this.dataSourceToSend.data[0].type=="UNI")
        var sub_type = "uni";
      else if(this.dataSourceToSend.data[0].type=="COMBI+")
        var sub_type = "combi";
      else
        var sub_type = "ppl";
    }

    this._devices.getLatestVersion(type, sub_type)
      .subscribe(data => {
        this.urlUpdate = data.url;
      })
  }

  sendCommand(){
    this.updateStatus = 1;
    let promises : any[] = [];
    if(this.command==1){
      this.dataSourceToSend.data.forEach(element => {
        promises.push(this.reset(element, this.resetType).then(data => {}).catch(error => {}));
      });
    }
    if(this.command==2){
      this.dataSourceToSend.data.forEach(element => {
        promises.push(this.update(element, this.urlUpdate).then(data => {}).catch(error => {}));
      });
    }

    if(this.command ==3){
      this.dataSourceToSend.data.forEach(element => {
        (this.checkModules(element, promises));
      });
    }

    Promise.all(promises)
    .then(response => {
      console.log(response)
      this.updateStatus = 2;
      setTimeout(() => {
        this.updateStatus = 0;
      }, 5000);
    }). catch(error =>{
      console.log(error);
      this.updateStatus = 2;
      setTimeout(() => {
        this.updateStatus = 0;
      }, 5000);
    } )
  }



  checkModules(element, promises ){
      for (let index = 0; index < element.taskCheck.length; index++) {
      element.status = 1

      // PRUEBAS DEL SPL MANAGER
      if (element.taskCheck[index].module === 'SPL Manager' && this.taskCheckOption[1].check) {

        promises.push(new Promise((resolve, reject) => {
          this._devices.getModuleInfo(element.serial, 'spl').subscribe((data) => {

            TestSpl(data, element.serial, this.testList)
            element.taskCheck[0].check = true;
            element.status = 2;

            resolve(data);
          }, err => {
            element.status = 3;
            element.error = err.error.message;
            reject(err)
          })
        })
        )
      }

      // PRUEBAS DEL SCHEDULER MANAGER
      this.version = compare(element.version, '7.2.0', '>=');
      if (element.taskCheck[index].module === 'Scheduler Manager' && this.taskCheckOption[2].check && this.version) {
        promises.push(new Promise((resolve, reject) => {

          let infoModule;
          let state : any[] = [];
          let conn1;
          let conn2;

          this._devices.getElementModule(element.serial, 'evsm').subscribe( (data)=>{
            state = data;
            conn1 = data[0].name ;
            if ( data.length > 1) {
              conn2 = data[1].name ;
            }

            this._devices.getElementModule(element.serial, 'schedman').subscribe( (data2)=>{
              infoModule = data2;
              TestScheduler(data2, element.serial, this.testList, state );
            } )
            element.taskCheck[1].check = true;
            element.status = 2;
            resolve(infoModule);
          }, err => {
            element.status = 3;
            element.error = err.error.message;
            reject(err)
          })
        })
        )
      }

      // PRUEBAS DEL SOLAR MANAGER
      if (element.taskCheck[index].module === 'Solar Manager' && this.taskCheckOption[3].check) {
        promises.push(new Promise((resolve, reject) => {
          this._devices.getModuleInfo(element.serial, 'solar', 'cfg').subscribe((data) => {

            TestSolar(data, element.serial, this.testList)
            element.taskCheck[2].check = true;
            element.status = 2;

            resolve(data);
          }, err => {
            element.status = 3;
            element.error = err.error.message;
            reject(err)
          })
        })
        )
      }
    }
  }

  getTotal(module, serial){
    var total = 0;
    this.testList.forEach( item =>{
        if ( item.serial == serial && item.module == module)   total++;  })
    return total;
  }

  getParcial(module, serial){
    var parcial =0;
    this.testList.forEach( item =>{if ( item.serial == serial && item.module == module){ if ( item.result) parcial++ }
    })
    return parcial ;
  }

  update(device, url) {
    return new Promise((resolve, reject) => {
      device.status = 1;
      this._devices.updateFw(device.serial, url)
      .subscribe(data=>{
        device.status = 2;
        resolve(data);
      }, err=>{
        device.status = 3;
        device.error = err.error.message;
        reject(err)
      })
    });
  }

  reset(device, type) {
    return new Promise((resolve, reject) => {
      device.status = 1;
      this._devices.reset(device.serial, type)
      .subscribe(data => {
        device.status = 2;
        resolve(data);
      }, err => {
        device.status = 3;
        device.error = err.error.message;
        reject(err);
      });
    });
  }

  checkActiveButton(){
    if((this.command == 1 || (this.command == 2 && this.urlUpdate!='')) && this.dataSourceToSend.data.length > 0 || this.command == 3)
      return true;
    else
      return false;
  }

  changeAllDevicesMode(mode){
    if(mode != this.getAllDevices){
      this.getAllDevices = mode;
      this.getDevices();
    }
  }

  getDevices(){
    this.charging=true;
    this._devices.getDevices()
    .subscribe(data => {
      this.dataSource.data = []
      data.forEach(element => {
        this.dataSource.data.push({
          selected:false,
          serial: element.serial,
          type: (element.type=="device_evse"?"COMBI/CITY":(element.sub_type=="ppl"?"Contador":(element.sub_type=="uni"?"UNI":"COMBI+"))),
          version: element.firmware_version_name,
          status: 0,
          error: "Error desconocido",
          taskCheck : [
            { module : 'SPL Manager', check : false, ok : false  },
            { module : 'Scheduler Manager', check : false, ok : false  },
            { module : 'Solar Manager', check : false, ok : false  },
         ]
        })
      });
      setTimeout(() => {
        this.dataSource.paginator = this.paginatorAll;
        this.charging=false;
        setTimeout(() => {
          this.dataSource.sort = this.sort;
        }, 200);
       }, 200);
    });
  }


  changeTaskCheck(indice : number){

    if(this.taskCheckOption[0].check == false && indice == 0 ){
      for (let index = 0; index < this.taskCheckOption.length; index++) {
        this.taskCheckOption[index].check = false;
        this.buttonEnabled = true
      }
    }

    if(this.taskCheckOption[0].check == true && indice == 0 ){
      for (let index = 0; index < this.taskCheckOption.length; index++) {
        this.taskCheckOption[index].check = true;
        this.buttonEnabled = false
      }
    }

    if (indice != 0 && (this.taskCheckOption[indice].check || !this.taskCheckOption[indice].check)){
        this.taskCheckOption[indice].check ? false : true;
        this.taskCheckOption[0].check = false;
        this.buttonEnabled = true;
        for (let index = 0; index < this.taskCheckOption.length; index++) {
          if ( this.taskCheckOption[index].check == true )
            this.buttonEnabled = false
        }
      }
  }


  openCheckModal(element, index){

    var filtrado =  this.testList.filter(elemento => elemento.module === element.taskCheck[index].module && elemento.serial === element.serial );
    this.modalCheck =  this._modal.open(ModalCheckComponent);
    this.modalCheck.componentInstance.data = filtrado;
  }
}
