import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { DeviceService } from 'src/app/services/devices/device.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { ConnectorState } from 'src/assets/js/constants';
import { forkJoin, Observable, of } from 'rxjs';
import { mergeMap, map, concatMap, catchError, filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { LoadingPopupComponent } from 'src/app/components/ui/loading-popup/loading-popup.component';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { idTagInfo, LocalAuthorizationList } from 'src/app/shared/models/components.interface';



@Component({
  selector: 'app-show-group',
  templateUrl: './show-group.component.html',
  styleUrls: ['./show-group.component.scss']
})
export class ShowGroupComponent  implements OnInit{

  breadCrumbs : any;

  group: any;
  data: any = {}
  linkedDevices: any[] = [];
  // data: any[]=[];
  allStates = ConnectorState;
  modalLoad: any;
  updateStatus: number;
  status : any[] = []
  showStatus = false;
  modal : any;
  ampacity = 32;
  power = 0;
  url = '';
  port = 0;
  loading : boolean = true;
  confirm = {
    power : false,
    reset : false,
    ocpp: false,
    rfid: false,
    wifi : false,
    password : false,
    cards : false
  }

  selectAll = false;
  selectedDevices: any[] = [];

  chooseCfg : string = 'power';
  rfidEnabled = 0;
  rfidMode = 0;
  eocMode : string | 'any' | 'own' = 'any';
  messageDelCards = false;
  cardsMode : string | 'upload' | 'delete' = 'upload'
  ssid = '';
  wifiPass = '';
  newPass = '';
  accessPoint = 1;
  addBoxId : boolean  = false;

  event = new EventEmitter();
  file: File;
  tagList : any;

  constructor(private _router: Router,
              private _modal: ModalService,
              private _devices: DeviceService,
              private route: ActivatedRoute,
              private _translate: TranslateService,
              private _dashboard: DashboardService
            ) {              }


  ngOnInit() {
    this.group = null
    this._dashboard.getBreadCrumbs().subscribe(
      data =>{
         this.breadCrumbs = data;
      }
    )

    this.route.params.subscribe(params => {

      this.loading= true;

      this.group = params['infoGroup'] || null;


      if (this.group != null) {

        this._devices.getGroup(this.group).pipe(
          // Encadenar las peticiones de los dispositivos en el grupo
          concatMap(dataGroup => {
            if (dataGroup.devices.length > 0) {
              const deviceRequests = dataGroup.devices.map(device =>
                // Ejecutar ambas peticiones para cada dispositivo en paralelo
                forkJoin({
                  deviceInfo: this._devices.getDeviceInfo(device.serial).pipe(  catchError(error => of({ error, message: 'Error fetching deviceInfo data' })) ),
                  evsmData: this._devices.getElementModule(device.serial, 'evsm'),
                  element: this._devices.getElemDevice(device.serial),
                  modulator: this._devices.getModuleInfo(device.serial, 'modulator'),
                  solar: this._devices.getModuleInfo(device.serial, 'solar'),
                  rfid: this._devices.getModuleInfo(device.serial, 'rfid'),
                  scheduler: this._devices.getElementModule(device.serial, 'schedman',
                    // device.serial.includes('EVVC3') || device.serial.includes('EVVC5') ? 'mennekes' : 'mennekes1'
                    !device.serial.includes('EVVC4')  ? 'mennekes' : 'mennekes1'
                  ),
                  spl: this._devices.getModuleInfo(device.serial, 'spl'),

                  scheduler2: this._devices.getElementModule(device.serial, 'schedman',
                    !device.serial.includes('EVVC4')  ? 'schuko' : 'mennekes2'
                  ).pipe(  catchError(error => of({ error, message: 'Error fetching scheduler2 data' })) ) ,
                  ocpp: this._devices.getModuleInfo(device.serial, 'ocpp', 'stat', { localdata: true }).pipe(
                    catchError(error => of({ error, message: 'Error fetching OCPP data' }))
                  ),
                  reqman: this._devices.getElementModule(device.serial, 'reqman',
                    // device.serial.includes('EVVC3') || device.serial.includes('EVVC5') ? 'mennekes' : 'mennekes1' )
                    !device.serial.includes('EVVC4')  ? 'mennekes' : 'mennekes1')
                }).pipe(
                  // Combinar los datos del dispositivo con los resultados de las peticiones
                  map(({ deviceInfo, evsmData, element , modulator, solar,rfid, scheduler, scheduler2, ocpp, reqman, spl
                    // , solar, rfid, scheduler, scheduler2, ocpp, reqman
                      }) => ({
                    ...device,           // Información original del dispositivo
                    deviceInfo,       // Combinar todas las propiedades de deviceInfo directamente
                    evsmData,        // Combinar todas las propiedades de evsmData directamente
                    element,
                    modulator,
                    solar,
                    rfid,
                    scheduler,
                    scheduler2,
                    ocpp,
                    reqman,
                    spl
                  }))
                )
              );

              // Esperar a que todas las llamadas a dispositivos se resuelvan antes de continuar
              return forkJoin(deviceRequests).pipe(
                map(updatedDevices => ({
                  ...dataGroup,
                  devices: updatedDevices // Sobrescribir los dispositivos con la nueva info

                }))
              );
            } else {
              // Si no hay dispositivos, devolver el grupo padre tal cual
              return of(dataGroup);
            }
          })
        ).subscribe(
          updatedGroup => {
            this.data = updatedGroup;
            this._dashboard.replaceBreadCrumbs(this.breadCrumbs);
            this.sortData();
            this.selectedDevices = []
            this.data.devices.forEach(device => {
              this.status.push({ serial: device.serial, status: 3 })
              this.selectedDevices.push({serial: device.serial, check: false})
            });
            this.loading=false
          },
          error => {
            console.error('Error fetching data:', error);
          }
        );
      }
    });

  }

  getTranslatedText(translationKey: string) {
    if (translationKey)
      return this._translate.instant(translationKey);
    else
      return null;
  }


  updateBreadCrumbs(alias: string, index: number): void {
    const currentBreadCrumbs = this.breadCrumbs;
    const updatedBreadCrumbs = currentBreadCrumbs.slice(0, index +1);
    this._dashboard.replaceBreadCrumbs(updatedBreadCrumbs )
    if (index === 0) {
      this._dashboard.setModoDashboard('list')
      this._router.navigate(['dashboard']);
    } else {
      this._router.navigate(['dashboard/group', alias]);
    }
  }


  configSPL(){

  }

  getLinkedDevices(subgroup){

    this._devices.getLinkedDevices(subgroup)
      .subscribe( data => {
          this.linkedDevices = data
      })
  }

  goToDevice(serial) {
    this._router.navigateByUrl(`/dashboard/device/${serial}`)
  }


  reset(){
      this.confirm.reset = false;
      this.modalLoad=this._modal.open(LoadingPopupComponent);
      for (let index = 0; index < this.selectedDevices.length; index++) {
        if (this.selectedDevices[index].check){
          this._devices.reset( this.selectedDevices[index].serial, 'hard')
          .subscribe( data => {
            this.status[index].status = 1
          }, err => {
            console.log(err);
            this.status[index].status = 2
          }
        )
       }
      }
      setTimeout(() => {
        this.modalLoad.close();
        this.showStatus = true;
      }, 1000);
      setTimeout(() => {
        this.showStatus = false;
      }, 5000);
    // }
  }

  setLimitPower() {
    this.confirm.power = false;
    this.modalLoad = this._modal.open(LoadingPopupComponent);
    for (let index = 0; index < this.selectedDevices.length; index++) {
      if (this.selectedDevices[index].check) {
        if (!this.selectedDevices[index].serial.includes('EVVC4') && this.ampacity > 32) this.ampacity = 32;
        this._devices.setModuleCfg(this.selectedDevices[index].serial, { ampacity: this.ampacity, }, 'sys')
          .subscribe(data => {
            this.status[index].status = 1
          }, err => {
            console.log(err);
            this.status[index].status = 2
          }
          )
      }
    }
    setTimeout(() => {
      this.modalLoad.close();
      this.showStatus = true;
    }, 1000);
    setTimeout(() => {
      this.showStatus = false;
    }, 5000);
  }



  ocpp() {
      this.confirm.ocpp = false;
      this.modalLoad = this._modal.open(LoadingPopupComponent);
      for (let index = 0; index < this.data.devices.length; index++) {
        if (this.selectedDevices[index].check){
          var body = {
            serverUrl: this.addBoxId && this.url !="" ?  this.url + this.data.devices[index].serial : this.url ,
            // serverUrl: this.url,
            port: this.port
          }

          this._devices.setModuleCfg(this.data.devices[index].serial, body, 'ocpp')
            .subscribe(data => {
              this.status[index].status = 1;
            }, err => {
              console.log(err);
              this.status[index].status = 2;
            })
        }
      }
      setTimeout(() => {
        this.modalLoad.close();
        this.showStatus = true;
      }, 1000);
      setTimeout(() => {
        this.showStatus = false;
      }, 5000);
  }

  setRFID(){
    this.confirm.rfid = false;
    this.modalLoad=this._modal.open(LoadingPopupComponent);
    var body = {
      enabled : this.rfidEnabled,
      rfid : this.rfidMode,
      eocMode : this.eocMode
    }
    for (let index = 0; index < this.selectedDevices.length; index++) {
      if (this.selectedDevices[index].check){
        this._devices.setModuleCfg( this.selectedDevices[index].serial, body, 'rfid' )
        .subscribe( data => {
          this.status[index].status = 1
        }, err => {
          this.status[index].status = 2;
        } )
      }
    }
    setTimeout(() => {
      this.modalLoad.close();
      this.showStatus = true;
    }, 1000);
    setTimeout(() => {
      this.showStatus = false;
    }, 5000);
  }

  setWifi(){
    this.confirm.wifi = false;
    this.modalLoad=this._modal.open(LoadingPopupComponent);
    var body = {
        staEssid: this.ssid,
        staPasswd: this.wifiPass
    }
    for (let index = 0; index < this.selectedDevices.length; index++) {
      if (this.selectedDevices[index].check){
        this._devices.setModuleCfg( this.selectedDevices[index].serial, body, 'network' )
        .subscribe( data => {
          this.status[index].status = 1
        }, err => {
          this.status[index].status = 2;
        } )
      }
    }
    setTimeout(() => {
      this.modalLoad.close();
      this.showStatus = true;
    }, 1000);
    setTimeout(() => {
      this.showStatus = false;
    }, 5000);
  }

  setPassword(){
    this.confirm.password = false;
    this.modalLoad=this._modal.open(LoadingPopupComponent);
    var body = {
      apPasswd: this.newPass,
      apEnabled : this.accessPoint
    }
    for (let index = 0; index < this.selectedDevices.length; index++) {
      if (this.selectedDevices[index].check){
        this._devices.setModuleCfg( this.selectedDevices[index].serial, body, 'network' )
        .subscribe( data => {
          this.status[index].status = 1
        }, err => {
          this.status[index].status = 2;
        } )
      }
    }
    setTimeout(() => {
      this.modalLoad.close();
      this.showStatus = true;
    }, 1000);
    setTimeout(() => {
      this.showStatus = false;
    }, 5000);

  }

  toggleAllDevices(){
    this.selectedDevices.forEach(device => {
      device.check = this.selectAll;
    });
  }


  openModal(){
    this.chooseCfg = 'power';
      this.cancel();
  }

  cancel(){
    this.confirm = {
      power : false,
      reset : false,
      ocpp: false,
      rfid: false,
      wifi : false,
      password : false,
      cards : false
    };
    this.cardsMode = 'upload';
    this.messageDelCards = false;
  }

  checkAnyFieldTrue(): boolean {
    return Object.values(this.confirm).some(value => value === true);
  }

  messageConfirm(){
    if (this.confirm.power ) return "setPowerAll";
    if (this.confirm.reset) return "resetAll";
    if (this.confirm.ocpp) return "setOcppAll";
    if (this.confirm.rfid) return "setRfidAll";
    if (this.confirm.wifi) return "setWifiAll";
    if (this.confirm.password) return "setPassAll";
    if (this.confirm.cards) return "setCardsAll";
    return ''
  }

  changeMode(modo : string){
    if ( modo === 'touch'){ this.rfidEnabled = 1; this.rfidMode = 0};
    if ( modo === 'rfid') { this.rfidEnabled = 1; this.rfidMode = 1};
    if ( modo === 'off')  { this.rfidEnabled = 0};
  }

  setCards(modo :string ){
    this.confirm.cards = false;
    this.modalLoad=this._modal.open(LoadingPopupComponent);
    for (let index = 0; index < this.selectedDevices.length; index++) {
      if (this.selectedDevices[index].check){
        if ( modo === 'delete'){
          this._devices.deleteRfidListCard( this.selectedDevices[index].serial)
          .subscribe( data => {
            this.status[index].status = 1
          }, err => {
            this.status[index].status = 2;
          } )
        }else{
          this._devices.modifyRfidListCards( this.selectedDevices[index].serial , this.tagList)
          .subscribe( data => {
            this.status[index].status = 1
          }, err => {
            this.status[index].status = 2;
          } )
        }
      }
    }
    setTimeout(() => {
      this.modalLoad.close();
      this.showStatus = true;
    }, 1000);
    setTimeout(() => {
      this.showStatus = false;
    }, 5000);
  }



  sortData(){
    this.data.devices.sort((a, b) => {
      const modeA = a.spl.cfg.splMode;
      const modeB = b.spl.cfg.splMode;

      if (modeA === 1) return -1; // Coloca primero los 'splmode' con valor 1
      if (modeB === 1) return 1;

      if (modeA === 2) return -1; // Luego los 'splmode' con valor 2
      if (modeB === 2) return 1;

      return 0; // Finalmente los 'splmode' con valor 0
    });

  }

  home(){
    this._dashboard.setUpdateSidebar();
  }

  viewGroup(group) {
    this._router.navigate(['/dashboard/viewgroup', group]);
  }
  goToSPL(group : string){
    this._router.navigate(['/dashboard/splconfig', group]);
  }
  goTodetailGroup(group){
    this._router.navigate(['/dashboard/newgroup', group]);
  }

  infoGroup(group){
    this._dashboard.setBreadCrumbs({
      name: group.name,
      alias: group.alias
    });
    this._router.navigate(['/dashboard/group', group.alias]);
  }

  detailGroup(group){
    this._dashboard.setBreadCrumbs({
      name: group.name,
      alias: group.alias
    });
    this._router.navigate(['/dashboard/newgroup', group.alias]);
  }

  onFileSelected(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      this.file = inputElement.files[0];
       this.onUpload();
    }
  }


  async onUpload() {
    const workbook = XLSX.read(await this.file.arrayBuffer());
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    let raw_dat = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    let result = this.filterDuplicatedItems(raw_dat);
    let idTagRpt = this.checkIdTag(raw_dat)

    if (idTagRpt < 1) {
      if (result.duplicateRows.length < 1) {
        if (this.checkInvalidDates(result.uniqueRows) > 0) {
          console.log('---- ERROR ----')
        } else {
          try {
            const raw_data = this.getStatusCodeString(raw_dat);
            const xlsCardsArray: LocalAuthorizationList[] = [];
            for (let i = 1; i < raw_data.length; i++) {
              if(raw_data[i].length > 1){
                const item = raw_data[i];
                const idTag = item[0];
                const expiryDate = item[1] ? moment(item[1]).format('YYYY-MM-DDTHH:mm') : '';
                const parentIdTag = item[2];
                const status = item[3] ? item[3] : 'Accepted';
                const tagInfo: idTagInfo = { parentIdTag, expiryDate, status };
                const lal: LocalAuthorizationList = { idTag: idTag, idTagInfo: tagInfo };
                xlsCardsArray.push(lal);
              }
            }
            this.tagList = this.formatJson(xlsCardsArray);
          } catch (error) {
            console.log(error);
          }
        }
      }
      else {
        console.log('---- ERROR ----');
      }
    }
    else {
          console.log('----- Error al cargar archivo de tarjetas ------- ');
    }
  }

  filterDuplicatedItems(data: any): { uniqueRows: any[][]; duplicateRows: any[][] } {
    var uniqueRows: any[][] = [];
    var duplicateRows: any[][] = [];
    var seenValues: { [key: string]: boolean } = {};
    for (var i = 0; i < data.length; i++) {
      var row = data[i];
      var value = String(row[0]);
      if (row.length > 0 && !seenValues[value]) {
        uniqueRows.push(row);
        seenValues[value] = true;
      } else if (row.length > 0) {
        duplicateRows.push(row);
      }
    }
    return { uniqueRows: uniqueRows, duplicateRows: duplicateRows };
  }

  checkIdTag(obj: any[]): number {
    let count = 0;
    for (let i = 0; i < obj.length - 1; i++) {
      const array = obj[i];
      if (array.length === 0 || array[0] === null || array[0] === undefined || array[0] === '') {
        count++;
      }
    }
    return count;
  }

  checkInvalidDates(dataObject){
    const datesArray: string[] = dataObject.slice(1).map((item: any) => item[1]);
    let falseCount: number = 0;
    datesArray.forEach((item) => {
      if (!this.isValidDate(item)) {
        falseCount++;
      }
    });
    return falseCount;
  }
  isValidDate(dateString) {
    return dateString === '' || dateString === undefined|| moment(dateString, 'YYYY-MM-DDTHH:mm', true).isValid();
  }

  getStatusCodeString(array: any){
    const statusMap: { [key: number]: string } = {
        0: 'Accepted',
        1: 'Rejected',
        2: 'Expired',
        3: 'Invalid',
        4: 'In transaction',
        5: 'No credit',
        // 6: 'Blocked',
        // 7: 'ConcurrentTx',
        // 8: 'NotDefined'
    };

    const newArray = array.map(innerArray => {
      return innerArray.map(element => {
          if (typeof element === 'number') {
              return statusMap[element] || '';
          }
          return element;
      });
  });
  return newArray;
}

formatJson(jsonData: any[]): { file: string } {
  const csvData = jsonData.reduce((acc, obj, i) => {
    const idTag = obj.idTag || '';
    const expiryDate = obj.idTagInfo?.expiryDate || '';
    const parentIdTag = obj.idTagInfo?.parentIdTag || '';
    const status = obj.idTagInfo.status ? this.getCodeFromStatus(obj.idTagInfo.status) : 0;
    const statusCode = status;
    const lineSeparator = i === 0 ? '\n' : '';
    return `${acc}${lineSeparator}${idTag};${expiryDate};${parentIdTag};${statusCode}\r\n`;
  }, '2\r');

  return { file: csvData };}


getCodeFromStatus(status: string): number | string {
  const statusMap: { [key: string]: number | string } = {
    'Accepted': 0,
    'Rejected': 1,
    'Expired': 2,
    'Invalid': 3,
    'In transaction': 4,
    'No credit': 5,
    //'Blocked': 6,
    //'ConcurrentTx': 7,
    //'NotDefined': 8
  };
  const code = statusMap[status];
  return code !== undefined ? code : '';
}

}
