import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { LoadingPopupComponent } from 'src/app/components/ui/loading-popup/loading-popup.component';
import { ModalService } from 'src/app/services/modal/modal.service';
import { Auth, verifyPasswordResetCode, confirmPasswordReset, checkActionCode } from '@angular/fire/auth';
import { CustomModalComponent } from 'src/app/components/ui/custom-modal/custom-modal.component';
import { HttpClient } from '@angular/common/http'; // <-- Para enviar la petición POST (ej. a /api/verifyAccount)
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.scss']
})
export class RecoveryComponent implements OnInit {

  // Para resetPassword
  userInfo = {
    password: ''
  };

  // Para verifyEmail: guardaremos nombre y apellidos
  verifyInfo = {
    name: '',
    lastName: ''
  };

  email: string = '';
  modalLoad: NgbModalRef;
  modalOk: NgbModalRef;
  errorModal: NgbModalRef;

  rePass = '';
  error = false;
  errorPasswords = false;

  apiKey: string = '';
  oobCode: string = '';
  mode: string = '';

  // Estados para la verificación de email
  isLoading = false;
  isCodeValid = false;  // Indica si el oobCode es válido
  showForm = false;     // Muestra el formulario con nombre y apellidos
  errorMessage = '';    // Si el checkActionCode falla, guardamos un mensaje de error

  constructor(
    private _modal: ModalService, 
    private _translate: TranslateService, 
    private _aRoute: ActivatedRoute, 
    private _auth: Auth, 
    private _router: Router,
    private _userService: UserService
  ) {}

  ngOnInit(): void {
    this._aRoute.queryParams.subscribe(params => {
      this.oobCode = params['oobCode'];
      this.mode = params['mode'];
      this.apiKey = params['apiKey'];

      if (this.mode === 'verifyEmail') {
        // Al entrar en modo verifyEmail, validamos el oobCode
        this.checkActionCodeValidity();
      } else if (this.mode === 'resetPassword') {
        this.verifyPasswordResetCode();
      }
    });
  }

  // 1. Verificar si el oobCode es válido para la verificación de email
  checkActionCodeValidity(): void {
    this.isLoading = true;
    this.modalLoad = this._modal.open(LoadingPopupComponent, { centered: true });

    checkActionCode(this._auth, this.oobCode)
      .then(() => {
        // Si llega aquí, el oobCode es válido
        this.isCodeValid = true;
        this.showForm = true;
      })
      .catch(error => {
        // El oobCode no es válido o ha expirado
        this.isCodeValid = false;
        this.errorMessage = this._translate.instant('linkNotValid'); 
        console.error('checkActionCode error:', error);
      })
      .finally(() => {
        this.isLoading = false;
        this.modalLoad.dismiss();
      });
  }

  // 2. Cuando el usuario hace clic en "Verificar" (con nombre y apellidos llenos)
  onVerifyAccount(): void {
    if (!this.verifyInfo.name || !this.verifyInfo.lastName) {
      this.errorModal = this._modal.open(CustomModalComponent);
      this.errorModal.componentInstance.title = this._translate.instant('error');
      this.errorModal.componentInstance.description = this._translate.instant('fillNameSurname');
      return;
    }
  
    this.modalLoad = this._modal.open(LoadingPopupComponent, { centered: true });
  
    this._userService.verifyUserByAdministrator(
      this.oobCode,
      this.apiKey,
      this.verifyInfo.name,
      this.verifyInfo.lastName,
      navigator.language
    ).subscribe({
      next: (res: any) => {
        this.modalLoad.dismiss();
  
        this.modalOk = this._modal.open(CustomModalComponent);
        this.modalOk.componentInstance.title = this._translate.instant('accountVerification');
        this.modalOk.componentInstance.description = this._translate.instant('accountVerificationOk');
        
        this.modalOk.closed.subscribe(() => {
          this._router.navigate(['/login']).then(() => {
            window.location.reload();
          });
        });
      },
      error: (err) => {
        this.modalLoad.dismiss();
        console.error(err);
        alert(this._translate.instant('accountVerificationError'));
      }
    });
  }  
  
  // Lógica para resetPassword
  verifyPasswordResetCode(): void {
    verifyPasswordResetCode(this._auth, this.oobCode)
      .then((email: string) => {
        this.email = email;
      })
      .catch((error: any) => {
        this.error = true;
        this.modalOk = this._modal.open(CustomModalComponent);
        this.modalOk.componentInstance.title = this._translate.instant('error');
        this.modalOk.componentInstance.description = this._translate.instant('linkNotValid');
      });
  }

  recover(): void {
    this.error = false;
    this.errorPasswords = false;

    if (this.rePass !== this.userInfo.password) {
      this.errorPasswords = true;
      return;
    }

    this.modalLoad = this._modal.open(LoadingPopupComponent);
    confirmPasswordReset(this._auth, this.oobCode, this.userInfo.password)
      .then(() => {
        this.modalLoad.dismiss();

        this.modalOk = this._modal.open(CustomModalComponent);
        this.modalOk.componentInstance.title = this._translate.instant('recoverRestablished');
        this.modalOk.componentInstance.description = this._translate.instant('recoverRestablishedSub');

        setTimeout(() => this._router.navigate(['/login']), 1000);
      })
      .catch((error: any) => {
        this.modalLoad.dismiss();

        this.errorModal = this._modal.open(CustomModalComponent);
        this.errorModal.componentInstance.title = this._translate.instant('error');
        this.errorModal.componentInstance.description = this._translate.instant('recoverPassError');
      });
  }

  goToLogin(): void {
    this._router.navigate(['/login']);
  }

}
