import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public user: Observable<firebase.User | null >;
  public isLoggedIn: boolean;
  public userToken: string | null = null;

  constructor(private _router: Router, public auth:  AngularFireAuth) {
    this.user = this.auth.authState;
    this.isLoggedIn = false;

    // Suscripción al observable de idTokenResult para mantener el token actualizado
    this.auth.idTokenResult.subscribe((tokenResult) => {
      if (tokenResult) {
        this.userToken = tokenResult.token;
      } else {
        this.userToken = null;
      }
    });

    this.refreshAuthenticationStatus();
  }

  login(username, password): Promise< firebase.auth.UserCredential> {
    return this.auth.signInWithEmailAndPassword(username, password);
  }

  register(userInfo) {
    return this.auth.createUserWithEmailAndPassword(userInfo.email,userInfo.password);
  }

  get authenticated():boolean {
    return this.isLoggedIn;
  }

  get currentUser(): Observable<firebase.User | null> {
    return this.user;
  }

  refreshAuthenticationStatus(){
    this.user.subscribe((user) => {
      if (user) {
        this.isLoggedIn = true;
        // El token se actualiza en la suscripción a idTokenResult, no es necesario repetir
        user.getIdTokenResult().then((tokenResult) => {
          this.userToken = tokenResult?.token || null;
        });
      } else {
        this.isLoggedIn = false;
        this.userToken = null;
      }
    });
  }

  verifyEmail() {
    return this.currentUser.subscribe({
      next: (user) => {
        user!.sendEmailVerification();
      },
      error: (err) => {
        console.log("verifyEmail:", err);
      }
    });
  }

  updateProfile(name?) {
    return this.currentUser.subscribe((user) => {
      user?.updateProfile({
        displayName: (name) ? name : user?.displayName,
      });
    });
  }

  public logout(): void {
    sessionStorage.clear();
    Cookie.deleteAll();

    this.auth.signOut().then(() => {
      this.isLoggedIn = false;
      this._router.navigate(['/login']);
    });
  }

  resetPassword(email: string) {
    return this.auth.sendPasswordResetEmail(email);
  }
}
