import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Params } from "@angular/router";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ErrorModalComponent } from "src/app/components/ui/error-modal/error-modal.component";
import { LoadingPopupComponent } from "src/app/components/ui/loading-popup/loading-popup.component";
import { ModalConfirmComponent } from "src/app/components/ui/modal-confirm/modal-confirm.component";
import { DeviceService } from "src/app/services/devices/device.service";
import { ModalService } from "src/app/services/modal/modal.service";
import { UserService } from "src/app/services/user/user.service";
import { SelectInpI } from "src/app/shared/models/components.interface";
import { UserDevice } from "src/app/shared/models/modals.interface";

@Component({
  selector: 'app-linkage',
  templateUrl: './linkage.component.html',
  styleUrls: ['./linkage.component.scss'],
})
export class LinkageComponent implements OnInit {
  users: UserDevice [] = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @Input() allSettings = false;
  dataSource = new MatTableDataSource<any>(this.users);

  roles: SelectInpI[];
  userToUnlink:any;
  userToTransfer: any;
  user: any = '';
  selectedUsers: any = [];
  device: string = '';
  modal: NgbModalRef;
  loadingModal: NgbModalRef;
  modalSuccess: NgbModalRef;
  modalError: NgbModalRef;
  buttonOpenConfirm: any;
  buttonCloseConfirm: any;
  buttonOpenUnlinkConfirm: any;
  buttonCloseUnlinkConfirm: any;
  buttonOpenTransferConfirm: any;
  buttonCloseTransferConfirm: any;
  body = {
    serial: '',
    name: '',
  };
  response = {
    success: false,
    error: false,
  };
  error404 = false
  error500 = false
  deviceOwner = false;
  roleActiveUser: any;
  admin : boolean = false;

  constructor(
    private _aRoute: ActivatedRoute,
    private _user: UserService,
    private _device: DeviceService,
    private _modal: ModalService,
    private _translate: TranslateService
  ) {}

  ngOnInit(): void {
    this._aRoute.parent?.parent?.params.subscribe((params: Params) => (this.device = params['serial']));
    this.buttonOpenConfirm = document.getElementById('toggle-name-modal');
    this.buttonCloseConfirm = document.getElementById('dismiss-confirm-modal');
    this.buttonOpenUnlinkConfirm = document.getElementById('unlink-modal');
    this.buttonCloseUnlinkConfirm = document.getElementById('dismiss-unlink-modal');
    this.buttonOpenTransferConfirm = document.getElementById('transfer-modal');
    this.buttonCloseTransferConfirm = document.getElementById('dismiss-transfer-modal');
    this.roles = [
      {value: 'all', viewValue: 'All'},
      {value: 'read', viewValue: 'Read'},
      {value: 'write', viewValue: 'Write'},
      {value: 'owner', viewValue: 'Owner'},
    ]

    this._user.getUserInfo()
    .subscribe( (data) => {
      data.role === 'admin' ? this.admin = true : this.admin = false;
    } )

    var deviceInfo = this._device.getActiveDeviceInfo();
    if (deviceInfo) {
      this.deviceOwner = (deviceInfo.user_build == this._device.getUserActive()) || sessionStorage.getItem('role') == 'admin' || sessionStorage.getItem('role') == 'user';
      this.roleActiveUser = sessionStorage.getItem('role');
    }
    this.getLinkedUsers();
  }

  /*USER FUNC*/
  getLinkedUsers() {
    this._device.getLinkedUsersToCharger(this.device).subscribe((data) => {
      this.users = data;
      console.log(this.users);
      if (this.roleActiveUser !== 'admin') { //solo filtra si el activeUser no es admin
        this.users = this.users.filter(user => user.user_device_role.toString() !== 'admin');
      }//si eres user y propietario del cargador no debes ver a los admins
      this.dataSource.data = this.users;
      this.dataSource.paginator = this.paginator;
    });
  }
  unlinkUser() {
    let userMail = this.userToUnlink.user_email;
    this.buttonCloseUnlinkConfirm?.click();
    this.loadingModal = this._modal.open(LoadingPopupComponent);
    this.error404 = false
    this.error500 = false;

    this._device.unlinkUserFromCharger(userMail, this.device).subscribe((data) => {
        console.log(data);
        this.loadingModal.close();
        this.modalSuccess = this._modal.open(ModalConfirmComponent);
        this.modalSuccess.componentInstance.title = this._translate.instant('unlinkSuccess');
        this.getLinkedUsers();
      },
      (err) => {
        console.error(err);
        this.response.error = true;
        if (err.status === 404) {
          this.loadingModal.close();
          this.modalError = this._modal.open(ErrorModalComponent);
          this.modalError.componentInstance.description = this._translate.instant('unlinkError');
          this.error404 = true;
          this.resetForm();
        } else if (err.status === 500) {
          this.loadingModal.close();
          this.modalError = this._modal.open(ErrorModalComponent);
          this.modalError.componentInstance.description = this._translate.instant('unlinkError');
          this.error500 = true;
          this.resetForm();
        }
      }
    );
    (err) => {
      this.response.error = true;
      if (err.status === 404) {
        this.error404 = true;
        this.loadingModal.close();
        this.modalError = this._modal.open(ErrorModalComponent);
        this.modalError.componentInstance.description = this._translate.instant('unlinkError');
        this.resetForm();
      }
    };
  }
  setUnlink(user){
    this.buttonOpenUnlinkConfirm?.click();
    this.userToUnlink = user;

  }
  setEdit(user){
    this.buttonOpenTransferConfirm?.click();
    this.userToTransfer = user.user_name + ' ' + user.user_last_name;
    this.user = user;
  }
  editRoles(){
    this.buttonCloseTransferConfirm?.click();
    this.manageModalForms(this.device, this.user.user_email, this.roles.values);
  }

  /*TABLE FUNC*/
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  selectUser(tester: any) {
    const index = this.selectedUsers.indexOf(tester);
    if (index > -1) {
      this.selectedUsers.splice(index, 1);
    } else {
      this.selectedUsers.push(tester);
    }
  }
  isAllSelected() {
    const numSelected = this.selectedUsers.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  masterToggle() {
    this.isAllSelected()
      ? this.clearSelected()
      : this.dataSource.data.forEach((row) => this.selectedUsers.push(row));
  }
  clearSelected() {
    this.selectedUsers = [];
  }

  /*LINK USER*/
  save() {
    this.buttonOpenConfirm?.click();
  }
  assign(newUser) {

    this.buttonCloseConfirm?.click();
    this.manageModalForms(this.device, newUser, this.roles.values)
  }
  resetForm(){
    this.user = '';
    this.body.serial = '';
  }
  manageModalForms(device, value, role){
    this.loadingModal = this._modal.open(LoadingPopupComponent);
    this.error404 = false
    this.error500 = false;

    this._device.assignDevices(device, value, role).subscribe((data) => {
        this.loadingModal.close();
        this.modalSuccess = this._modal.open(ModalConfirmComponent);
        this.modalSuccess.componentInstance.title = this._translate.instant('device_assigned_successfully');
        this.getLinkedUsers();
        this.user = ''
      },
      (err) => {
        this.response.error = true;
        if (err.status === 404) {
          this.loadingModal.close();
          this.error404 = true;
          this.resetForm();
        } else if (err.status === 500) {
          this.loadingModal.close();
          this.error500 = true;
          this.resetForm();
        }
      }
    );
    (err) => {
      this.response.error = true;
      if (err.status === 404) {
        this.error404 = true;
        this.loadingModal.close();
        this.resetForm();
      }
    };
  }
}
