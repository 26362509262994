import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ErrorModalComponent } from 'src/app/components/ui/error-modal/error-modal.component';
import { LoadingPopupComponent } from 'src/app/components/ui/loading-popup/loading-popup.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DeviceService } from 'src/app/services/devices/device.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  username = '';
  password = '';

  loginErrorText='';

  errorModal: NgbModalRef;
  modalLoad: NgbModalRef;

  showLogin: boolean = false;

  prod = environment.production;

  constructor(private _auth: AuthService, private _router: Router, private _translate: TranslateService, private _modal: NgbModal, private _devices: DeviceService) {
    this.token = undefined;

   }

   params = new URLSearchParams(window.location.search);
   token: string|undefined;

   captchaRef = undefined;

  ngOnInit(): void {

    // Para detectar el idioma del navegador y aplicarlo a la app
    // console.log(  'Idioma del navegador  '+ this._translate.getBrowserLang() );
    this._translate.use( this._translate.getBrowserLang() );


    this._auth.user.subscribe(async data => {
      if(data && data.emailVerified){
         // Forzar token fresco
         await data.getIdToken(true);
         this.modalLoad=this._modal.open(LoadingPopupComponent, {centered: true});
         this.userAuthenticated();
       }else{
         this.showLogin=true;
       }
     });

  }

  async checkRecaptcha(event){
    this.token = event;
    if(this.token){
      this.login();
    }
  }

  login(){
    this.modalLoad=this._modal.open(LoadingPopupComponent, {centered: true});
    this.loginErrorText=this._translate.instant('invalidCredentials');
    this.showLogin=false;

    this._auth.login(this.username, this.password)
      .then(data=>{
        data.user?.getIdToken(true).then(token=>{
          this.modalLoad.close();
          this.userAuthenticated();
        });
      }, err=>{
        console.error(err);
        this.modalLoad.close();
        this.errorModal=this._modal.open(ErrorModalComponent);
        this.errorModal.componentInstance.description=this._translate.instant("invalidCredentials");
        this.showLogin=true;
      })
  }
  userAuthenticated() {
    let role = sessionStorage.getItem('role');
    let redirectUrl = localStorage.getItem('redirectUrl'); // Obtiene la URL guardada
    this._devices.getDevices().subscribe(
      (devices) => {
        this.modalLoad.close();

        if (redirectUrl) {
          // Si hay una URL guardada, redirige a ella
          this._router.navigateByUrl(redirectUrl);
          localStorage.removeItem('redirectUrl'); // Limpia la URL después de redirigir
        } else if (role == 'admin') {
          // Redirige al panel de administración si es administrador
          this._router.navigate(['/dashboard/administration/search']);
        } else if (devices.length == 1) {
          // Si solo tiene un dispositivo, redirige directamente a él
          this._router.navigate(['/dashboard/device', devices[0].serial]);
        } else {
          // Redirige al dashboard por defecto
          this._router.navigate(['/dashboard']);
        }
      },
      (error) => {
        console.log(error);
        this.modalLoad.close();
      }
    );
  }
}
