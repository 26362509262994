<div [ngClass]="{'col-12 col-xl-6' : !allSettings}">
    <div class="card">
        <div class="card-header">
            <h5 class="card-title">{{"ocppServer" | translate}}</h5>
            <h6 class="card-subtitle text-muted">{{"ocppDescription" | translate}}</h6>
        </div  >

        <div class="d-flex justify-content-end me-4">
          <div>
            <!-- <button class="me-1" matTooltip="Eliminar configuración ocpp" matTooltipPosition="above" mat-raised-button -->
            <button class="me-1" matTooltip="{{ 'deleteCfgOcpp' | translate }}" matTooltipPosition="above" mat-raised-button
              [ngStyle]="{'background-color': bgColorCfg, 'color': iconColorCfg}" (click)="deleteCfg()">
              <div *ngIf="!spinnerCfg"> <i class="fas fa-trash"></i> </div>
              <div *ngIf="spinnerCfg" class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>
            <button class="me-1" matTooltip="{{ 'testOcpp' | translate }}" matTooltipPosition="above" mat-raised-button
              [ngStyle]="{'background-color': bgColorTest, 'color': iconColorTest}" (click)="test()">
              <div *ngIf="!spinnerTest"><i> ocpp</i> </div>
              <div *ngIf="spinnerTest" class="spinner-border spinner-border-sm" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            </button>
            <button matTooltip="Reset" matTooltipPosition="above" class="ms-1" mat-raised-button
               [ngStyle]="{'background-color': bgColorReset, 'color': iconColorReset}"  (click)="reset()">
              <i *ngIf="!spinnerReset"  class="fas fa-sync-alt"></i>
              <div *ngIf="spinnerReset" class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>
          </div>
        </div>
        <div *ngIf="warning" class="text-right me-4 mt-2"> {{ 'warningOcpp' | translate}} </div>

        <div class="card-body">
            <form>
                <div class="form-group row">
                    <label class="col-form-label col-sm-4 text-sm-right">Url</label>
                    <div class="col-sm-8">
                        <input type="text" class="form-control" placeholder="Url" name="serverUrl" [(ngModel)]="ocppCfg.serverUrl"
                         (keyup)="changesMade=_device.checkChangesSettings(originalData,ocppCfg)">
                         <div  *ngIf="!changeUrl() && ocppCfg.serverUrl !='' " [ngClass]="{'text-danger ': !changeUrl()}">{{"error" | translate }}  {{' Url'}} </div>
                         <div  *ngIf="ocppCfg.serverUrl =='' " > {{ 'noUrlOcpp' | translate }}</div>
                        </div>
                      </div>
                <div class="form-group row">
                    <label class="col-form-label col-sm-4 text-sm-right">{{"port" | translate}}</label>
                    <div class="col-sm-8">
                        <input type="number" class="form-control" [placeholder]="'port' | translate" name="port" [(ngModel)]="ocppCfg.port" min="1" max="65535"
                         (change)="changePort() ;changesMade=_device.checkChangesSettings(originalData,ocppCfg)">
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-sm-10 ml-sm-auto">
                        <button type="submit" class="btn btn-primary" (click)="save()" [disabled]="!changesMade || !changeUrl()">{{"overwrite" | translate}}</button>
                        <button type="submit" class="btn btn-light ml-2" [disabled]="!changesMade">{{"restore" | translate}}</button>
                    </div>

                </div>
            </form>
            <div class="alert alert-danger alert-dismissible mt-2" role="alert" *ngIf="error!=''">
                <div class="alert-message">
                    {{ error }}
                </div>
            </div>
            <div class="alert alert-success alert-dismissible mt-2" role="alert" *ngIf="successMessage">
                <div class="alert-message">
                    {{"updatedSuccesfully" | translate}}
                </div>
            </div>
        </div>
    </div>
</div>

