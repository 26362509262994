import { Component } from '@angular/core';
import { NavigationStart, Router, Event } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { VerificationModalComponent } from './components/ui/verification-modal/verification-modal.component';
import { AuthService } from './services/auth/auth.service';
import { ThemeService } from './services/theme/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'orbis-iot-web';
  currentRoute: string;
  verificationModal: NgbModalRef;

  verificationModalOptions: NgbModalOptions = {
    backdrop : 'static',
    keyboard : false,
    centered : true,
  }

  constructor(
    translate: TranslateService,
    private _theme: ThemeService,
    private _router: Router,
    private _auth: AuthService,
    private _modal: NgbModal
    ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
   translate.use(navigator.language.substring(0,2));

   this._theme.setLightTheme();

   var test = document.getElementById('userDropdown');

   test?.addEventListener('click', ()=>console.log('clicked'))

   this.currentRoute = this._router.url;

   this._router.events.subscribe((event: Event) => {
    if (event instanceof NavigationStart) {
      if (!event.url.includes('/register') && !event.url.includes('/forgot') && !event.url.includes('/recover')) {
        this._auth.user.subscribe(data=>{
          if(data?.emailVerified === false && !this.verificationModal){
            this.verificationModal = this._modal.open(VerificationModalComponent, this.verificationModalOptions)
          }
        });
      }
    }
   });


  }

}
