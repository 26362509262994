import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-verification-modal',
  templateUrl: './verification-modal.component.html',
  styleUrls: ['./verification-modal.component.scss'],
  providers: [NgbModal]
})
export class VerificationModalComponent implements OnInit {

  @Input()
  description: string;
  title: string;
  sendResult: string;
  resendVerificationSpan: string;
  resendingVerification:boolean = false;
  checkVerificationSpan: string;
  checkingVerification:boolean = false;

  constructor(
    private activeModal: NgbActiveModal, 
    private _auth: AuthService,
    private translate: TranslateService
  ) {
    this.description = this.translate.instant('verificationDescription');
    this.title = this.translate.instant('verificationTitle');
    this.resendVerificationSpan = this.translate.instant('verificationResend');
    this.checkVerificationSpan = this.translate.instant('verificationCheck');
  }

  ngOnInit(): void { }

  reCheckUserValidation(){
    this.checkVerificationSpan = this.translate.instant('verificationChecking');
    this.checkingVerification = true;
    this._auth.user.subscribe(data=>{
      if(data?.emailVerified){
        this.activeModal.close();
      }else{
        this.sendResult = this.translate.instant('verificationEmailNotVerified');
      }
      setTimeout(() => {
        this.checkVerificationSpan = this.translate.instant('verificationCheck');
        this.checkingVerification = false;
      }, 1000);
    });

  }

  resendVerification(){
    this.resendVerificationSpan = this.translate.instant('verificationSending');
    this.resendingVerification = true;
    try {
      this._auth.verifyEmail();
      this.sendResult = this.translate.instant('verificationEmailSent');

    } catch (error) {
      this.sendResult = this.translate.instant('verificationEmailSendError');
    }
    //Metemos un pequeño delay
    setTimeout(() => {
      this.resendVerificationSpan = this.translate.instant('verificationResend');
      this.resendingVerification = false;
    }, 1000);
  }

  logout(){
    this.activeModal.close();
    this._auth.logout();
  }

}
