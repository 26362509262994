export const environment = {
  production: true,
  baseUrl: 'https://apiv3.orbis.com.es',
  logsServer: 'wss://apiv3.orbis.com.es',
  socketServer: 'wss://apiv3.orbis.com.es',
  remoteLoggerDevices: 'wss://apiv3.orbis.com.es', /*hay que revisar esta url para montar el servidor de remote logger en el front1*/
  firebase: {
    apiKey: "AIzaSyBYOx4tebNIvWAZYrabR1sA3sNOf3vBahs",
    authDomain: "orbis-iot-app.firebaseapp.com",
    projectId: "orbis-iot-app",
    messagingSenderId: "475073369275",
    appId: "1:475073369275:web:25ac1502f78953af42459e",
    measurementId: "G-TYBT7XYGXV"
  },
  recaptcha: {
    enabled: true, // Deshabilitar reCAPTCHA en desarrollo
    siteKey: '',
  }
};
