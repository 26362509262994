import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private url = environment.baseUrl;

  constructor(private _http: HttpClient, private _auth: AuthService) {
    if(this._auth.userToken){
      this.getUserInfo();
    }
   }

  public userInfo: any = {};

  getHeaders = () => {
    return new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this._auth.userToken
    });
  }

  getUserInfo(){
    return this._http.get<any>(this.url+'/user', {headers: this.getHeaders()});
  }

  updateUser(name?, surname?, alias?, lang?){
    let body = {
        name,
        last_name: surname,
        alias,
        language: lang
    }

    return this._http.put<any>(this.url+'/user/', JSON.stringify(body), {headers: this.getHeaders()}).toPromise();
  }

  assignDevice(body){
    return this._http.post<any>(this.url + '/user/addDevice', JSON.stringify(body), { headers: this.getHeaders() });
  }

  generateToken(){
    return this._http.post<any>(this.url + '/user/token', {}, { headers: this.getHeaders() });
  }

  getUserPATToken(){
    return this._http.get<any>(this.url + '/user/token', { headers: this.getHeaders() });
  }

  deleteUserPATToken(token){
    return this._http.delete<any>(this.url + '/user/token/'+token, { headers: this.getHeaders() });
  }

  askDeveloperMode() {
    return this._http.post<any>(this.url + '/user/developer', {}, { headers: this.getHeaders() });
  }

  verifyUserByAdministrator(oobCode: string, apiKey: string, name: string, lastName: string, language: string) {
    const payload = {
      oobCode: oobCode,
      apiKey: apiKey,
      userData: {
        name: name,
        last_name: lastName,
        language: language
      }
    };
  
    return this._http.post<any>(
      `${this.url}/user`, 
      payload
    );
  }
  
  unsubscribeAsTester(email){
    return this._http.delete<any>(this.url + '/user/tester', { headers: this.getHeaders() });
  }
  requestTester(email, language){
    let body = {
      email : email,
      language : language
    }
    return this._http.post<any>(this.url + '/user/tester', JSON.stringify(body), { headers: this.getHeaders() });
  }

  getTesters(){
    return this._http.get<any>(this.url + '/user/tester', { headers: this.getHeaders() });
  }

  downgradeTesters(testers: any[]){
    let body = {
      testers : testers
    }
    return this._http.put<any>(this.url + '/user/tester/downgrade', JSON.stringify(body), { headers: this.getHeaders() });
  }

  confirmTesters(testers: any[]){
    let body = {
      testers : testers
    }
    return this._http.put<any>(this.url + '/user/tester/confirm', JSON.stringify(body), { headers: this.getHeaders() });
  }

  resendTelegramInviteLink(testers: any[]){
    let body = {
      testers : testers
    }
    return this._http.post<any>(this.url + '/user/tester/telegram-invite', JSON.stringify(body), { headers: this.getHeaders() });
  }
}
